<template>
  <div>
    <div class="content" style="background: #FFFFFF">
      <div class="content_item">
        <p class="title">PDA功能编辑/添加</p>
        <el-divider></el-divider>
        <el-form label-width="80px" label-position="top" :model="form">
          <el-form-item label="客户名称">
            <el-input
              style="width: 500px"
              v-model="form.userName"
              placeholder="请输入用户名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="四码合一">
            <div class="Code">
              <div :class="className1">
                <div class="Code_item_number">
                  <img :src="detectionImg1" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  id="tags"
                  label="IMEI"
                ></el-checkbox>
              </div>
              <div :class="className2">
                <div class="Code_item_number">
                  <img :src="detectionImg2" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  id="tags"
                  label="设备编码"
                ></el-checkbox>
              </div>
              <div :class="className3">
                <div class="Code_item_number">
                  <img :src="detectionImg3" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  id="tags"
                  label="资产编码"
                ></el-checkbox>
              </div>
              <div :class="className4">
                <div class="Code_item_number">
                  <img :src="detectionImg4" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  id="tags"
                  label="RFID"
                ></el-checkbox>
              </div>
              <div :class="className5">
                <div class="Code_item_number">
                  <img :src="detectionImg5" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  label="资产贴纸"
                  id="tags"
                ></el-checkbox>
              </div>
              <div :class="className6">
                <div class="Code_item_number">
                  <img :src="detectionImg6" alt="" />
                </div>
                <el-checkbox
                  v-model="integrationCode"
                  label="包装资产贴纸"
                  id="pack"
                ></el-checkbox>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="四码合一长度">
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input1_data.data"
              :disabled="length_input1_state"
              class="input"
              placeholder="请输入IMEI长度"
            ></el-input>
            <br />
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input2_data.data"
              :disabled="length_input2_state"
              class="input"
              placeholder="请输入设备编码长度"
            ></el-input>
            <br />
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input3_data.data"
              :disabled="length_input3_state"
              class="input"
              placeholder="请输入资产编码长度"
            ></el-input>
            <br />
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input4_data.data"
              :disabled="length_input4_state"
              class="input"
              placeholder="请输入RFID长度"
            ></el-input>
            <br />
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input5_data.data"
              :disabled="length_input5_state"
              class="input"
              placeholder="请输入资产贴纸长度"
            ></el-input>
            <br />
            <el-input
              maxlength="64"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.number="length_input6_data.data"
              :disabled="length_input6_state"
              class="input"
              placeholder="请输入包装资产贴纸长度"
            ></el-input>
          </el-form-item>
          <el-form-item id="det" label="功能检测">
            <el-checkbox-group v-model="detection">
              <el-checkbox label="数据更新时间"></el-checkbox>
              <el-checkbox label="控制器软件版本"></el-checkbox>
              <el-checkbox label="位置信息"></el-checkbox>
              <el-checkbox label="温度信息"></el-checkbox>
              <el-checkbox label="开关门次数"></el-checkbox>
              <el-checkbox label="人流量"></el-checkbox>
              <el-checkbox label="摄像头"></el-checkbox>
              <el-checkbox label="远程设定温度"></el-checkbox>
              <el-checkbox label="开关机"></el-checkbox>
              <el-checkbox label="照明开关"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="buttons">
      <el-button class="button_cancel" @click="cancel()">取消</el-button>
      <el-button class="button_preserve" type="primary" @click="preserve()"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { Adduser } from "@/api/AssetManagement";
export default {
  name: "addFunction",
  props: ["compileData", "type"],
  data() {
    return {
      length_input1_state: true,
      length_input2_state: true,
      length_input3_state: true,
      length_input4_state: true,
      length_input5_state: true,
      length_input6_state: true,
      length_input1_data: { data: "", number: 1 },
      length_input2_data: { data: "", number: 2 },
      length_input3_data: { data: "", number: 3 },
      length_input4_data: { data: "", number: 4 },
      length_input5_data: { data: "", number: 5 },
      length_input6_data: { data: "", number: 6 },
      className1: "Code_item",
      className2: "Code_item",
      className3: "Code_item",
      className6: "Code_item",
      className4: "Code_item",
      className5: "Code_item",
      integrationCode: [],
      checked1: true,
      checked2: true,
      checked3: true,
      checked4: true,
      checked5: true,
      checked6: true,
      count: 0,
      detectionImg1: require("../../../../assets/images/number_normal.png"),
      detectionImg2: require("../../../../assets/images/number_normal.png"),
      detectionImg3: require("../../../../assets/images/number_normal.png"),
      detectionImg4: require("../../../../assets/images/number_normal.png"),
      detectionImg5: require("../../../../assets/images/number_normal.png"),
      detectionImg6: require("../../../../assets/images/number_normal.png"),
      form: {
        userName: ""
      },
      userItem: [
        {
          value: "国风集团",
          lable: "国风集团"
        }
      ],
      detection: []
    };
  },
  watch: {
    //监听修改

    //监听四码合一
    integrationCode: function(val) {
      // let integrationCode = [];
      if (val.length != 0) {
        this.className1 = "Code_item";
        this.className2 = "Code_item";
        this.className3 = "Code_item";
        this.className4 = "Code_item";
        this.className5 = "Code_item";
        this.className6 = "Code_item";
        this.length_input1_state = true;
        this.length_input2_state = true;
        this.length_input3_state = true;
        this.length_input4_state = true;
        this.length_input5_state = true;
        this.length_input6_state = true;
        this.detectionImg1 = require("../../../../assets/images/number_normal.png");
        this.detectionImg2 = require("../../../../assets/images/number_normal.png");
        this.detectionImg3 = require("../../../../assets/images/number_normal.png");
        this.detectionImg4 = require("../../../../assets/images/number_normal.png");
        this.detectionImg5 = require("../../../../assets/images/number_normal.png");
        this.detectionImg6 = require("../../../../assets/images/number_normal.png");
        for (let i = 0; i < val.length; i++) {
          if (val[i] == "IMEI") {
            this.className1 = "Code_item_click";
            this.detectionImg1 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input1_state = false;
          } else if (val[i] == "设备编码") {
            this.className2 = "Code_item_click";
            this.detectionImg2 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input2_state = false;
          }
          if (val[i] == "资产编码") {
            this.className3 = "Code_item_click";
            this.detectionImg3 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input3_state = false;
          }
          if (val[i] == "RFID") {
            this.className4 = "Code_item_click";
            this.detectionImg4 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input4_state = false;
          }
          if (val[i] == "资产贴纸") {
            this.className5 = "Code_item_click";
            this.detectionImg5 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input5_state = false;
          }
          if (val[i] == "包装资产贴纸") {
            this.className6 = "Code_item_click";
            this.detectionImg6 = require(`../../../../assets/images/number_selected_${i +
              1}.png`);
            this.length_input6_state = false;
          }
        }
        if (this.length_input1_state) {
          this.length_input1_data = { data: "", number: 1 };
        }
        if (this.length_input2_state) {
          this.length_input2_data = { data: "", number: 2 };
        }
        if (this.length_input3_state) {
          this.length_input3_data = { data: "", number: 3 };
        }
        if (this.length_input4_state) {
          this.length_input4_data = { data: "", number: 4 };
        }
        if (this.length_input5_state) {
          this.length_input5_data = { data: "", number: 5 };
        }
        if (this.length_input6_state) {
          this.length_input6_data = { data: "", number: 6 };
        }
      }
    }
  },
  created() {
    this.getCompileData();
  },
  methods: {
    getCompileData() {
      if (this.compileData) {
        this.form.userName = this.compileData.customerName;
        let code = this.compileData.fourCodeOrderList;
        let codeCopy = [];
        for (let i = 0; i < code.length; i++) {
          codeCopy.push({ name: code[i], number: i + 1 });
        }
        for (let i = 0; i < codeCopy.length; i++) {
          if (codeCopy[i].name == 1) {
            codeCopy[i].name = "IMEI";
          } else if (codeCopy[i].name == 2) {
            codeCopy[i].name = "设备编码";
          } else if (codeCopy[i].name == 3) {
            codeCopy[i].name = "资产编码";
          } else if (codeCopy[i].name == 4) {
            codeCopy[i].name = "RFID";
          } else if (codeCopy[i].name == 5) {
            codeCopy[i].name = "资产贴纸";
          } else if (codeCopy[i].name == 6) {
            codeCopy[i].name = "包装资产贴纸";
          }
        }
        for (let i = 0; i < codeCopy.length; i++) {
          if (codeCopy[i].number == 1) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            console.log(this.compileData.fourCodeOrderLengthList[num]);
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
            console.log(num);
          } else if (codeCopy[i].number == 2) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            // this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[num]
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
          } else if (codeCopy[i].number == 3) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            // this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[num]
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
          } else if (codeCopy[i].number == 4) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            // this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[num]
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
          } else if (codeCopy[i].number == 5) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            // this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[num]
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
          } else if (codeCopy[i].number == 6) {
            this.integrationCode.push(codeCopy[i].name);
            let num = codeCopy[i].number - 1;
            // this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[num]
            if (codeCopy[i].name == "IMEI") {
              this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "设备编码") {
              this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产编码") {
              this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "RFID") {
              this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "资产贴纸") {
              this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            } else if (codeCopy[i].name == "包装资产贴纸") {
              this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[
                num
              ];
            }
          }
        }
        // for (let i = 0;i<codeCopy.length;i++){
        //     if(codeCopy[i].number == 1){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input1_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input1_data.data)
        //     }else if(codeCopy[i].number == 2){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input2_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input2_data.data)
        //     }else if(codeCopy[i].number == 3){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input3_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input3_data.data)
        //     }else if(codeCopy[i].number == 4){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input4_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input4_data.data)
        //     }else if(codeCopy[i].number == 5){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input5_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input5_data.data)
        //     }else if(codeCopy[i].number == 6){
        //         this.integrationCode.push(codeCopy[i].name)
        //         this.length_input6_data.data = this.compileData.fourCodeOrderLengthList[i]
        //         console.log(this.length_input6_data.data)
        //     }
        // }
        if (this.compileData.hasUpdateTime == 1) {
          this.detection.push("数据更新时间");
        }
        if (this.compileData.hasVersion == 1) {
          this.detection.push("控制器软件版本");
        }
        if (this.compileData.hasLocation == 1) {
          this.detection.push("位置信息");
        }
        if (this.compileData.hasTemp == 1) {
          this.detection.push("温度信息");
        }
        if (this.compileData.hasDoorNum == 1) {
          this.detection.push("开关门次数");
        }
        if (this.compileData.hasVisitorFlow == 1) {
          this.detection.push("人流量");
        }
        if (this.compileData.hasCamera == 1) {
          this.detection.push("摄像头");
        }
        if (this.compileData.hasSetTemp == 1) {
          this.detection.push("远程设定温度");
        }
        if (this.compileData.hasOnOff == 1) {
          this.detection.push("开关机");
        }
        if (this.compileData.hasLighting == 1) {
          this.detection.push("照明开关");
        }
      }
    },
    //取消
    cancel() {
      this.detectionImg1 = require("../../../../assets/images/number_normal.png");
      this.detectionImg2 = require("../../../../assets/images/number_normal.png");
      this.detectionImg3 = require("../../../../assets/images/number_normal.png");
      this.detectionImg4 = require("../../../../assets/images/number_normal.png");
      this.detectionImg5 = require("../../../../assets/images/number_normal.png");
      this.className1 = "Code_item";
      this.className2 = "Code_item";
      this.className3 = "Code_item";
      this.className4 = "Code_item";
      this.className5 = "Code_item";
      this.$emit("getMessage", "");
      this.detection = [];
      this.integrationCode = [];
      this.form.userName = "";
      this.$router.push("/dashboard/client/PDA");
    },
    //添加用户
    addUser() {
      let state = false;
      let integrationCode = [];
      let lenthData = [
        this.length_input1_data,
        this.length_input2_data,
        this.length_input3_data,
        this.length_input4_data,
        this.length_input5_data,
        this.length_input6_data
      ];
      let lenthDatas = [];
      for (let i = 0; i < this.integrationCode.length; i++) {
        integrationCode.push({
          name: this.integrationCode[i],
          number: i + 1
        });
      }
      // this.integrationCode = []
      for (let i = 0; i < integrationCode.length; i++) {
        if (integrationCode[i].name === "IMEI") {
          integrationCode[i].name = 1;
        } else if (integrationCode[i].name === "设备编码") {
          integrationCode[i].name = 2;
        } else if (integrationCode[i].name === "资产编码") {
          integrationCode[i].name = 3;
        } else if (integrationCode[i].name === "RFID") {
          integrationCode[i].name = 4;
        } else if (integrationCode[i].name === "资产贴纸") {
          integrationCode[i].name = 5;
        } else if (integrationCode[i].name === "包装资产贴纸") {
          integrationCode[i].name = 6;
        }
      }
      this.integrationCode = [];
      for (let i = 0; i < integrationCode.length; i++) {
        for (let j = 0; j < lenthData.length; j++) {
          if (integrationCode[i].name == lenthData[j].number) {
            lenthDatas.push(lenthData[j].data);
          }
        }
        if (integrationCode[i].name == 1) {
          this.integrationCode.push(integrationCode[i].name);
        } else if (integrationCode[i].name == 2) {
          this.integrationCode.push(integrationCode[i].name);
        } else if (integrationCode[i].name == 3) {
          this.integrationCode.push(integrationCode[i].name);
        } else if (integrationCode[i].name == 4) {
          this.integrationCode.push(integrationCode[i].name);
        } else if (integrationCode[i].name == 5) {
          this.integrationCode.push(integrationCode[i].name);
        }
        if (integrationCode[i].name == 6) {
          this.integrationCode.push(integrationCode[i].name);
        }
      }
      for (let i = 0; i < lenthDatas.length; i++) {
        if (lenthDatas[i] == "") {
          this.$message({
            message: "四码合长度不能为空！",
            type: "error",
            duration: 1000
          });
          state = false;
          this.integrationCode = [];
          return;
        } else {
          state = true;
        }
      }
      let data = {
        fourCodeOrderLengthList: lenthDatas,
        customerName: this.form.userName,
        fourCodeOrder: this.integrationCode,
        hasCamera: 0,
        hasDoorNum: 0,
        hasLighting: 0,
        hasLocation: 0,
        hasOnOff: 0,
        hasSetTemp: 0,
        hasTemp: 0,
        hasUpdateTime: 0,
        hasVersion: 0,
        hasVisitorFlow: 0
      };
      if (this.compileData) {
        // console.log(this.compileData.customerId)
        data.customerId = this.compileData.customerId;
      }
      for (let i = 0; i < this.detection.length; i++) {
        if (this.detection[i] == "数据更新时间") {
          data.hasUpdateTime = 1;
        } else if (this.detection[i] == "控制器软件版本") {
          data.hasVersion = 1;
        } else if (this.detection[i] == "位置信息") {
          data.hasLocation = 1;
        } else if (this.detection[i] == "温度信息") {
          data.hasTemp = 1;
        } else if (this.detection[i] == "开关门次数") {
          data.hasDoorNum = 1;
        } else if (this.detection[i] == "人流量") {
          data.hasVisitorFlow = 1;
        } else if (this.detection[i] == "摄像头") {
          data.hasCamera = 1;
        } else if (this.detection[i] == "远程设定温度") {
          data.hasSetTemp = 1;
        } else if (this.detection[i] == "开关机") {
          data.hasOnOff = 1;
        } else if (this.detection[i] == "照明开关") {
          data.hasLighting = 1;
        }
      }
      if (state) {
        Adduser(data).then(res => {
          if (res.data.resultCode == 0) {
            this.$message({
              message: "添加成功！",
              type: "success",
              duration: 1000
            });
            this.$emit("getMessage", "");
            this.integrationCode = [];
            this.detection = [];
            this.form.userName = "";
            this.className1 = "Code_item";
            this.className2 = "Code_item";
            this.className3 = "Code_item";
            this.className4 = "Code_item";
            this.className5 = "Code_item";
            this.detectionImg1 = require("../../../../assets/images/number_normal.png");
            this.detectionImg2 = require("../../../../assets/images/number_normal.png");
            this.detectionImg3 = require("../../../../assets/images/number_normal.png");
            this.detectionImg4 = require("../../../../assets/images/number_normal.png");
            this.detectionImg5 = require("../../../../assets/images/number_normal.png");
            this.$router.push("/dashboard/client/PDA");
          } else {
            this.length_input1_data = { data: "", number: 1 };
            this.length_input2_data = { data: "", number: 2 };
            this.length_input3_data = { data: "", number: 3 };
            this.length_input4_data = { data: "", number: 4 };
            this.length_input5_data = { data: "", number: 5 };
            this.length_input6_data = { data: "", number: 6 };
            this.integrationCode = [];
            this.$message({
              message: res.data.desc,
              type: "error",
              duration: 1000
            });
          }
        });
      }
    },
    preserve() {
      this.addUser();
    }
  }
};
</script>

<style scoped>
.input {
  width: 500px;
  margin-bottom: 10px;
}
#pack {
  margin-left: 10px;
}
#pack /deep/.el-checkbox__label {
  padding: 0;
}
/deep/ .el-divider--horizontal {
  margin-top: 10px !important;
  margin-bottom: 10px;
}
.content {
  width: 100%;
  height: 990px;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
  background: #fff;
}
.content_item {
  padding: 35px;
}
/dppe/ .content_item .el-select > .el-input {
  width: 500px !important;
}
.title {
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #333333;
  letter-spacing: -0.05px;
}
.buttons {
  margin-top: 20px;
  margin-left: 10px !important;
}
.Code {
  display: flex;
  justify-content: space-between;
  height: 84px;
  width: 770px;
  margin-bottom: 10px;
}
.Code_item {
  width: 114px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  padding-top: 14px;
}
.Code_item_click {
  width: 114px;
  height: 100%;
  background: #ffffff;
  border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  padding-top: 14px;
  border: 2px solid #00aaa6;
}
.Code_item_number {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /*background: url("../../../../assets/images/number_normal.png") no-repeat;*/
  margin: auto;
}
/deep/ .el-checkbox {
  display: block;
  width: 30px;
  height: 25px;
}
/deep/ .Code_item .el-checkbox:last-of-type {
  margin-left: 30px;
}
/deep/ #tags {
  margin-left: 20px !important;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333;
}
/deep/ el-form-item__label {
  color: #333;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}
/deep/ #det .el-form-item__label {
  height: 25px;
}
/deep/ .el-select .el-input.is-focus .el-input__inner {
  width: 500px;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 30px;
  width: 500px;
}
</style>
